const industrialProduction = {
  productionQuarterEAEU: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'ЕАЭС' },
  },
  productionYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'ЕАЭС' },
  },
  productionQuarterRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'Россия' },
  },
  productionYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'Россия' },
  },
  productionQuarterBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'Беларусь' },
  },
  productionYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'Беларусь' },
  },
  productionQuarterArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'Армения' },
  },
  productionYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'Армения' },
  },
  productionQuarterKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'Кыргызстан' },
  },
  productionYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'Кыргызстан' },
  },
  productionQuarterKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_industrial/',
    params: { region: 'Казахстан' },
  },
  productionYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_industrial/',
    params: { region: 'Казахстан' },
  },
  productionQuarterAze: {
    method: 'GET',
    url: 'Azerbaijan/macro/quarterly_industrial/',
  },
  productionQuarterMol: {
    method: 'GET',
    url: 'Moldova/macro/quarterly_industrial/',
  },
  productionYearlyAze: {
    method: 'GET',
    url: 'Azerbaijan/macro/yearly_industrial/',
  },
};

export default industrialProduction;
